import React, { useState } from 'react';
import {
  Button ,
  TextField,
  FormControl ,
  FormLabel ,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText,
  Typography,
  Dialog ,
  DialogTitle,
  DialogContent ,
  DialogActions,
} from '@mui/material';
import Preview from './Preview';

const UploadStimDisplay = (props) => {
  const {
    selectedType,
    scriptData , 
    vastUrl, 
    file,
    preview,
    errors,
    isDragActive ,
    getRootProps, 
    getInputProps, 
    handleTypeChange ,
    setScriptData,
    setVastUrl,
    handleAddToUploadList ,
    uploading,
    handleUpload,
    setFile ,
    setPreview,


  } = props;

  const [previewModalOpen, setPreviewModalOpen] =useState(false);

  return (
    <div style={{ display:'flex', justifyContent: 'center' , marginTop:'20px'}}>
      <div
        style={{
          display:'flex' ,
          maxWidth: '1600px' ,
          width:'100%', 
          justifyContent: 'center' ,
          flexDirection:'column', 
        }}>
        <div
          style={{
            backgroundColor:'#fff' ,
            padding:'30px' ,
            borderRadius: '12px' ,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            width: '100%',
          }} >
<FormControl component="fieldset">
 
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: '600px',  
      margin: '0 auto', 
      width: '100%',
      marginBottom: '20px',
    }}
  >
    {/* Centered Title */}
    <FormLabel
  component="legend"
  style={{
    fontSize: '24px' ,
    marginBottom: '20px',
    fontWeight: 'bold' ,
    marginLeft: '950px' , 
    width: '100%' ,       
    overflow: 'hidden',  
  }}
>
      Choose Stim Type
    </FormLabel>

    {/* Centered Radio Buttons */}
    <RadioGroup
      aria-label="stimType"
      name="stimType"
      value={selectedType}
      onChange={handleTypeChange}
      row
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginLeft: '500px', 
      }}
    >
      <FormControlLabel
        value="s"
        control={<Radio style={{ display: 'none' }} />}
        label="SCRIPT"
        style={{
          margin: '0 20px',
          color: selectedType === 's' ? '#00badb' : '#000',
          borderBottom: selectedType === 's' ? '2px solid #00badb' : 'none',
          cursor: 'pointer',
          paddingBottom: '5px',
          transition: 'all 0.3s ease',
        }}
      />
      <FormControlLabel
        value="va"
        control={<Radio style={{ display: 'none' }} />}
        label="VAST URL/XML"
        style={{
          margin: '0 20px',
          color: selectedType === 'va' ? '#00badb' : '#000',
          borderBottom: selectedType === 'va' ? '2px solid #00badb' : 'none',
          cursor: 'pointer',
          paddingBottom: '5px',
          transition: 'all 0.3s ease',
        }}
      />
      <FormControlLabel
        value="iv"
        control={<Radio style={{ display: 'none' }} />}
        label="Image/Video"
        style={{
          margin: '0 20px',
          color: selectedType === 'iv' ? '#00badb' : '#000',
          borderBottom: selectedType === 'iv' ? '2px solid #00badb' : 'none',
          cursor: 'pointer',
          paddingBottom: '5px',
          transition: 'all 0.3s ease',
        }}
      />
    </RadioGroup>
  </div>
</FormControl>


          {selectedType === 's' &&(
            <TextField
              label="Script"
              required
              fullWidth
              multiline
              error={!!errors.scriptData}
              helperText={errors.scriptData}
              rows={4}
              value={scriptData}
              onChange={(e) => setScriptData(e.target.value)}
              style={{ marginBottom: '20px', backgroundColor: '#f0f0f0' }}
            /> )}

          {selectedType === 'va' && (
            <TextField
              label="VAST URL/XML"
              required
              fullWidth
              multiline
              error={!!errors.vastUrl}
              helperText={errors.vastUrl}
              rows={4}
              value={vastUrl}
              onChange={(e) => setVastUrl(e.target.value)}
              style={{ marginBottom: '20px', backgroundColor: '#f0f0f0' }}
         />)}

{selectedType === 'iv' && (
  <div
    {...getRootProps()}  
    style={{
      border: '2px dashed #ccc' ,
      padding : '20px', 
      marginBottom: '20px', 
      textAlign: 'center', 
      cursor : 'pointer' ,
      minHeight: '150px',
      backgroundColor : file.length > 0 ? '#f9f9f9':'transparent' ,
    }}
  >

    <input {...getInputProps({ multiple: true })} />
    {isDragActive ? (
      <p>Drop the files here...</p>
    ) : file.length > 0 ? (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <p style={{ marginBottom: '10px', fontWeight: 'bold', color: '#00badb' }}>
          Files:
        </p>
        {file.map((f, i) =>(
          <div
            key={i}
            style={{ 
              display:'flex',
              alignItems:'center' ,
              justifyContent: 'space-between' ,
              width: '90%', 
              backgroundColor: '#fff' , 
              border: '1px solid #eee' ,
              borderRadius: '6px' ,
              padding: '5px 10px' ,
              marginBottom:'8px', 
            }}
          >
            <span style={{ fontSize: '14px', overflow: 'hidden',textOverflow: 'ellipsis' }} >
              {f.name} 
            </span>
            <button

              type ="button" 
              onClick={(e) => {
                e.stopPropagation();  
                setFile(prev =>prev.filter((_, idx) => idx!== i));
                setPreview(prev =>prev.filter((_, idx) => idx!== i));}}
              style={{
                background: 'transparent',
                border: 'none',
                color: 'red',
                fontWeight: 'bold',
                fontSize: '16px', 
                cursor :  'pointer', 
              }}
            >
              ×
            </button>

          </div>

        ))}
      </div>
    ):(
      <p>Drag & Drop your images or videos here, or click to browse</p>
    )}
    {errors.file && <FormHelperText error>{errors.file}</FormHelperText>}

  </div>
)}

          
          <Button
            variant="outlined"
            onClick={() => setPreviewModalOpen(true)}
            style={{
              marginTop: '10px',
              padding: '10px',
              borderColor: '#00badb',
              color: '#00badb',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Preview Content
          </Button>

        
          <Button
            onClick={handleAddToUploadList}
            style={{
              marginTop: '10px',
              padding: '10px',
              backgroundColor: '#00badb',
              color: '#fff',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            Add Stim to Upload List
          </Button>

         
          {errors?.global && (
            <p style={{ color: 'red', marginTop: '10px' }}>{errors.global}</p>
          )}
        </div>

        
        <div style={{ marginTop: '30px', textAlign: 'center' }}>
          <Button
            variant="contained"
            onClick={handleUpload}
            disabled={uploading}
            style={{
              padding :'10px 20px', 
              backgroundColor: '#00badb', 
              color : '#fff',
              fontWeight:  'bold',
            }}
 >
            {uploading ? 'Uploading...' : 'Upload All Stims'}
          </Button>
        </div>

  
        <Dialog open={previewModalOpen } onClose={()=> setPreviewModalOpen(false)} fullWidth maxWidth="md">
          <DialogTitle >Preview Content</DialogTitle>
          <DialogContent style={{ textAlign: 'center' }}>
            {selectedType === 'iv' && Array.isArray(preview) && preview.length > 0 ? (
              preview.map((p,i) =>(
                <div key={i} style={{ marginBottom : '20px'}} >
                  {file[i]?.type?.startsWith('image') ? (
                    <img src={p} alt="preview" style={{ maxWidth : '100%', maxHeight : '300px' }} />
                  ) : (
                    <video controls style={{maxWidth:'100%', maxHeight : '300px' }}>
                      <source src={ p} type ={file[i]?.type || 'video/mp4'} />
                    </video>
                  )}
                </div>
              ))
            ) : (
              <Preview selectedType={selectedType} scriptData={scriptData } vastUrl={vastUrl} />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPreviewModalOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>

    </div>
  );
};

export default UploadStimDisplay;
