import React, { useState } from 'react';
import { Modal, FormControl, TextField, Button, Autocomplete, FormHelperText } from '@mui/material';

const CreateBrand = ({
  open,
  onClose,
  brandCategories,
  loadingCategories,
  newBrandName ,
  setNewBrandName ,
  newBrandCategory ,
  setNewBrandCategory,
  handleCreateBrand,
}) => {
  const [localErrors, setLocalErrors] = useState({});

  const onCreate = () => {
    const errors = {};
    if (!newBrandName.trim()) errors.name ='Brand name is required';
    if (!newBrandCategory) errors.category ='Please select a category';

    if (Object.keys(errors).length > 0) 
      {
      setLocalErrors(errors);
    } else 
    {
      setLocalErrors({});
      handleCreateBrand();
    }

  };

  return (

    <Modal open={ open} onClose={ onClose}>
      <div
        style={{

          padding: '2rem',
          backgroundColor: 'white',
          margin: '10% auto',
          width: '400px',
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          position: 'relative',
        }}
      >
        <span
          onClick={onClose}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            cursor: 'pointer',
            fontSize: '1.5rem',
            color: '#000',
          }}
        >
          &times;
        </span>

        <h2>Create New Brand</h2>

        <FormControl fullWidth margin= "normal" disabled = { loadingCategories}>
          <Autocomplete
            options={brandCategories}
            getOptionLabel={(option) => option.name || ''}
            onChange={(event, newValue) => setNewBrandCategory(newValue ? newValue.id :'')}
            renderInput={(params) =>(
              <TextField

                {...params}
                label= "Select a Category"
                placeholder= "Search Category" 
                error={!!localErrors.category}
                helperText={localErrors.category}
              />
            )}
          />
        </FormControl>

        <TextField
          label= "Brand Name"
          value={newBrandName}
          onChange={(e) =>setNewBrandName(e.target.value)}
          fullWidth
          margin= "normal"
          error= {!!localErrors.name}
          helperText= {localErrors.name}
        />

        <Button
          variant= "contained"
          onClick= {onCreate}
          style= {{ marginTop: '1rem' ,backgroundColor: '#00badb', color: '#fff' }}
        >
          Create Brand
        </Button>
        
      </div>
    </Modal>

  );
};

export default CreateBrand;
