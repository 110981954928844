import React, { useState } from 'react';
import {
  TextField,
  Autocomplete,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PreviewIcon from '@mui/icons-material/Visibility';
import Preview from './Preview';


const StimRow = ({
  index,
  stim,
  adAssetTypes,
  adTypeGroups,
  adTypes,
  brands,
  isSuperUser,
  brandCategories,
  onFieldChange,
  onDelete,
  onCreateBrand,
  errors = {},}) =>
     {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [editingTag, setEditingTag] = useState(stim.scriptData || stim.vastUrl || '');
  const [editError, setEditError] = useState('');

  const handleTagChange = (e) => {
    setEditingTag(e.target.value);
    if (e.target.value.trim() !== '') setEditError('');
  };

  const handleSaveTag =()=>{
    if (editingTag.trim() === '') {
      setEditError('This field cannot be empty.');
      return;
    }

    onFieldChange(index,{
      ...(stim.scriptData && { scriptData: editingTag }),
      ...(stim.vastUrl && { vastUrl: editingTag }),
    });

    setEditError('');
    setIsPreviewOpen(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        gap: '15px',
        width: '100%',
        backgroundColor: '#fafafa',
        padding: '15px',
        borderRadius: '8px',
        marginTop: '15px',
        overflowX: 'auto', }}
      >
      <TextField
        label="Name"
        value={stim.name}
        onChange={(e) => onFieldChange(index, { name: e.target.value })}
        error={!!errors.name}
        helperText={errors.name}
        required
          />

      <Autocomplete
        options={adAssetTypes}
        getOptionLabel={(option) => option.name || ''}
        value={adAssetTypes.find((item) => item.id === stim.adAssetTypeId) || null}
        onChange={(e, newVal) =>
          onFieldChange(index, { adAssetTypeId: newVal ? newVal.id : '' })
        }
        style={{ width: 200 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Ad Asset Type"
            error={!!errors.adAssetTypeId}
            helperText={errors.adAssetTypeId}
            required
          />
        )}
        />


      <Autocomplete

        options={adTypeGroups}
        getOptionLabel={(option) => option.name || ''}
        value={adTypeGroups.find((item) => item.id === stim.adTypeGroupId) || null}
        onChange={(e, newVal) =>
          onFieldChange(index, { adTypeGroupId: newVal ? newVal.id : '' })
        }
        style={{ width: 200 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Ad Type Group"
            error={!!errors.adTypeGroupId}
            helperText={errors.adTypeGroupId}
            required
          />
        )}
      />

      <Autocomplete

        options={adTypes}
        getOptionLabel={(option) => option.name || ''}
        value={adTypes.find((item) => item.id === stim.adTypeId) || null}
        onChange={(e, newVal) =>
          onFieldChange(index, { adTypeId: newVal ? newVal.id : '' })
        }
        style={{ width: 200 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Ad Type"
            error={!!errors.adTypeId}
            helperText={errors.adTypeId}
             required
            />  )}
       />

      <Autocomplete
        options={brands}
        getOptionLabel={(option) => option.name || ''}
        value={brands.find((item) => item.id === stim.brandId) || null}
        onChange={(e, newVal) =>
          onFieldChange(index, { brandId: newVal ? newVal.id : '' })
        }
        style={{ width: 200 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Brand"
            error={!!errors.brandId}
            helperText={errors.brandId}
            required
          />
        )}
      />

      {isSuperUser && (
        <Tooltip title="Create Brand">
          <Button
            onClick={onCreateBrand}
            style={{
              backgroundColor: '#00badb',
              color: '#fff',
              fontWeight: 'bold',
              padding: '6px 10px',
              minWidth: 'fit-content',
            }}
          >
            +
          </Button>
        </Tooltip>
      )}

      <Tooltip title="Preview/Edit">

        <IconButton onClick={() => setIsPreviewOpen(true)}>
          <PreviewIcon />
        </IconButton>

      </Tooltip>


      <Tooltip title="Delete">

        <IconButton onClick={() => onDelete(index)}>
          <DeleteOutlineIcon />
        </IconButton>
      </Tooltip>

  
      <Dialog open={isPreviewOpen} onClose={() => setIsPreviewOpen(false)} fullWidth maxWidth="md">
    <DialogTitle>Preview & Edit</DialogTitle>
  <DialogContent style={{ textAlign: 'center' }}>
    {stim.file ? (
      <>
        {stim.file.type.startsWith('image') ? (
          <img
            src={stim.preview}
            alt="preview"
            style={{ maxWidth: '100%', maxHeight: '400px', marginBottom: '10px' }}
          />
        ) : (
          <video controls style={{ maxWidth: '100%', maxHeight: '400px', marginBottom: '10px' }}>
            <source src={stim.preview} type={stim.file.type} />
          </video>
        )}

        {/* Delete + Upload New File buttons */}
        <div style={{ marginTop: '20px' }}>
      

          <Button
            variant="contained"
            component="label"
            style={{ backgroundColor: '#00badb', color: 'white' }}
          >
            Upload New File
            <input
              type="file"
              hidden
              accept="image/*,video/*"
              onChange={(e) => {
                const newFile = e.target.files[0];
                if (newFile) {
                  const newPreview = URL.createObjectURL(newFile);
                  onFieldChange(index, { file: newFile, preview: newPreview });
                }
              }}
            />
          </Button>
        </div>
      </>
    ) : (
      <Preview
        selectedType={stim.scriptData ? 's' : 'va'}
        scriptData={stim.scriptData}
        vastUrl={stim.vastUrl}/>
    )}
    {(stim.scriptData || stim.vastUrl) && (
      <TextField
        label="Edit"
        multiline
        fullWidth
        minRows={5}
        value={editingTag}
        onChange={handleTagChange}
        style={{ marginTop: '20px' }}
        error={!!editError}
        helperText={editError}  />
    )}
  </DialogContent>

  <DialogActions>
    <Button onClick={() => setIsPreviewOpen(false)}>Cancel</Button>
    <Button
      variant="contained"
      onClick={() => {
        handleSaveTag();
        setIsPreviewOpen(false);
      }}
      style={{ backgroundColor: '#00badb', color: '#fff' }}
    >
      Save

    </Button>

  </DialogActions>
</Dialog>
    </div>
  );
};
export default StimRow;
