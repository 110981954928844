import React from 'react';
import { Tabs, Tab, Paper } from '@mui/material';
import { withRouter } from 'react-router-dom';

class StudyTabNavigation extends React.Component {
  handleChange = (event, newValue) => {
    this.props.history.push(newValue);
  };

  render() {
    const { location, match } = this.props;
    const id = match.params.id || match.params.studyId;

    if (!id) {
      return null; 
    }

    const currentTab = (() => {
      if (location.pathname.includes('study-specific-stims')) return `/my_studies/overview/${id}/study-specific-stims`;
      if (location.pathname.includes('edit-details')) return `/my_studies/overview/${id}/edit-details`;
      if (location.pathname.includes('cell_step_management')) return `/my_studies/overview/${id}/cell_step_management`;
      return false;
    })();

    return (
      <Paper elevation={1} style={{ marginBottom: 24, position: 'sticky', top: 0, zIndex: 10 }}>
        <Tabs
          value={currentTab}
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
          variant="fullWidth"
        >
          <Tab label="Study Specific Stims" value={`/my_studies/overview/${id}/study-specific-stims`} />
          <Tab label="Edit Study Details" value={`/my_studies/overview/${id}/edit-details`} />
          <Tab label="Edit Study Structure" value={`/my_studies/overview/${id}/cell_step_management`} />
        </Tabs>
      </Paper>
    );
  }
}

export default withRouter(StudyTabNavigation);
