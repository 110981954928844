import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DevicesIcon from '@mui/icons-material/Devices';
import PreviewIcon from '@mui/icons-material/Preview';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import DoneIcon from '@mui/icons-material/Done';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import CloseIcon from '@mui/icons-material/Close';

import types from '../CellStepManagement/duck/types';
import { studiesOperations } from '../Studies/duck/index';
import LoadingSpinner from '../common/components/LoadingSpinner';
import Status from './components/Status';
import { overviewOperations } from './duck';
import ModalRoot from '../Modal/ModalContainer';
import { Typography, Button, Divider } from '@material-ui/core';
import StudySpecificStimsContainer from '../Stims/StudySpecificStimsComponent/StudySpecificStimsContainer';
import EditPanelMenu from './components/EditPanelMenu';
import StudyTimeline from './components/StudyTimeline';
import NewLinksTable from '../Links/components/NewLinksTable';
import { LinksOperations } from '../Links/duck/index';
import ConfirmActionModal from '../Modal/components/ConfirmActionModal';
import { userOperations } from '../MyDetails/duck';
import DataService from '../common/services/dataService';

const Overview = (props) => {
  const {
    getAudienceOverviewData,
    getOverviewData,
    fetchStudy,
    fetchStudySpecificStims,
    fetchStudyDetails,
    openModal,
    openConfirmationModal,
    confirmationModalOpen,
    closeConfirmationModal,
    modalOpen,
    match: {
      params: {
        id: studyId,
      }
    },
    panelOverview,
    loading,
    studiesOverview,
    panelLoading,
    overviewLoading,
    homeReducer,
    getLinksData,
    links,
    postEndLiveStudy,
    user,
    getUserData,
    studyActions,
  } = props;
  const payload = {id: studyId};
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAudienceOverviewData(studyId);
    getOverviewData(studyId);
    fetchStudy(payload);
    fetchStudySpecificStims(studyId);
    fetchStudyDetails(studyId);
    getLinksData(studyId);
    getUserData();
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  const handleOpenModal = (TYPE, panelId) => {
    const data = {
      type: types.SHOW_MODAL,
      modalType: TYPE,
      modalProps: {
        modalOpen,
        id: studyId,
        type: TYPE,
        panelId,
        panelOverview,
      }
    };
    openModal(studyId, data);
  };

  const handleOpenConfirmationModal = (TYPE, panel) => {

    const data = {
      type: types.SHOW_MODAL,
      modalType: TYPE,
      modalProps: {
        id: studyId,
        type: TYPE,
        panel,
        confirmationModalOpen,
        closeConfirmationModal,
      }
    };
    openConfirmationModal(studyId, data);
  };

  const submitSubscribeToStudy = async () => {
    await DataService.postAssignToAction(studyId);
    fetchStudyDetails(studyId);
  };

  if (loading || panelLoading || overviewLoading || isLoading) {
    return <LoadingSpinner />;
  }

  const startDate = studiesOverview.data_collection_start ? studiesOverview.data_collection_start.split('T')[0].split('-').reverse().join().replaceAll(',', '/') : '';
  const endDate = studiesOverview.data_collection_end ? studiesOverview.data_collection_end.split('T')[0].split('-').reverse().join().replaceAll(',', '/') : 'pending';
  const date = `${startDate} - ${endDate}`;

  return (
    <div >
      <Paper style={{ borderRadius: 16, padding: 16, minWidth: 1300, minHeight: '90vh' }}>
        <Typography style={{ textAlign: 'center', paddingTop: '12px' }} variant='h3'>{studiesOverview.study_name} Overview</Typography>
        <div className='overview__container'>
          <div style={{ display: 'grid', gap: 32, padding: '16px 16px', gridTemplateColumns: '3fr 5fr 3fr' }}>
            <Paper variant='outlined' style={{ borderRadius: 16, padding: 16, height: 'fit-content' }} >
              <Typography style={{ paddingBottom: 16 }} variant='h6'>Study details</Typography>
              {studiesOverview.brief ? (
                <Typography style={{ paddingBottom: 16, color: 'rgb(99, 115, 129)' }} variant='body2'>
                  {studiesOverview.brief}
                </Typography>
              ) : ''}
              <Typography
                style={{ paddingBottom: 16, color: 'rgb(99, 115, 129)', display: 'flex', alignItems: 'center', gap: 4 }}
                variant='body2'
              >
                <LocationOnIcon style={{ marginRight: 4 }} /> Language: <b> {studiesOverview.language}</b>
              </Typography>
              <Typography
                style={{ paddingBottom: 16, color: 'rgb(99, 115, 129)', display: 'flex', alignItems: 'center', gap: 4 }}
                variant='body2'
              >
                <DateRangeIcon style={{ marginRight: 4 }} />Data collection: <b>{date}</b>
              </Typography>
              <Typography
                style={{ paddingBottom: 16, color: 'rgb(99, 115, 129)', display: 'flex', alignItems: 'center', gap: 4 }}
                variant='body2'
              >
                <DevicesIcon style={{ marginRight: 4 }} />Environment: <b>{studiesOverview.environment}</b>
              </Typography>
              <Typography
                style={{ paddingBottom: 16, color: 'rgb(99, 115, 129)', display: 'flex', alignItems: 'center', gap: 4 }}
                variant='body2'
              >
                <PreviewIcon style={{ marginRight: 4 }} />Media: <b>{studiesOverview.media}</b>
              </Typography>
              <div style={{ width: '100%', display: 'flex' }}>
                <Button
                  style={{ padding: '3px 9px', border: 'unset', borderRadius: 8, textTransform: 'capitalize', margin: 'auto' }}
                  component={Link}
                  to={`/my_studies/overview/${studyId}/edit-details`}
                  variant='outlined'

                  endIcon={<ChevronRightIcon />}
                >
                  Edit Study Details
                </Button>
              </div>
              <Divider style={{ margin: '16px 0px' }} />
              <Typography style={{ paddingBottom: 16 }} variant='h6'>Study structure</Typography>
              <Typography
                style={{ paddingBottom: 16, color: 'rgb(99, 115, 129)', display: 'flex', alignItems: 'center', gap: 4 }}
                variant='body2'
              >
                <HouseOutlinedIcon
                  style={{ marginRight: 4 }}
                />Number of cells: <b>{studiesOverview.cell_step_stim_overview.cell_count}</b>
              </Typography>
              <Typography
                style={{ paddingBottom: 16, color: 'rgb(99, 115, 129)', display: 'flex', alignItems: 'center', gap: 4 }}
                variant='body2'
              >
                <ImageOutlinedIcon
                  style={{ marginRight: 4 }}
                />Number of stims: <b>{studiesOverview.cell_step_stim_overview.stim_count}</b>
              </Typography>
              <div style={{ width: '100%', display: 'flex' }}>
                <Button
                  style={{ padding: '3px 9px', border: 'unset', borderRadius: 8, textTransform: 'capitalize', margin: 'auto' }}
                  component={Link}
                  to={`/my_studies/overview/${studyId}/cell_step_management`}

                  variant='outlined'

                  endIcon={<ChevronRightIcon />}
                >
                  StructureEdit Study 
                </Button>
              </div>
              <Divider style={{ margin: '16px 0px' }} />
              <Typography
                style={{ paddingBottom: 16 }}
                variant='h6'
              >Survey details</Typography>
              <Typography
                style={{ paddingBottom: 16, color: 'rgb(99, 115, 129)', display: 'flex', alignItems: 'center', gap: 4 }}
                variant='body2'
              >
                <BallotOutlinedIcon
                  style={{ marginRight: 4 }}
                />Demographic survey  {studiesOverview.survey_overview.has_survey ?
                  <DoneIcon style={{fontSize:22}} color='success' /> :
                  <CloseIcon style={{fontSize:22}} color='error' />
                }  </Typography>
              <Typography
                style={{ paddingBottom: 16, color: 'rgb(99, 115, 129)', display: 'flex', alignItems: 'center', gap: 4 }}
                variant='body2'
              >
                <BallotOutlinedIcon style={{ marginRight: 4 }} />
                Recall survey  {studiesOverview.survey_overview.has_demographics ?
                  <DoneIcon style={{fontSize:22}} color='success' /> :
                  <CloseIcon style={{fontSize:22}} color='error' />
                }
              </Typography>
              <Typography
                style={{ paddingBottom: 16, color: 'rgb(99, 115, 129)', display: 'flex', alignItems: 'center', gap: 4 }}
                variant='body2'
              >
                <BallotOutlinedIcon style={{ marginRight: 4 }} />
                Number of questions: <b>{studiesOverview.survey_overview.num_questions}</b>
              </Typography>
              <Tooltip title='This button is permanently disabled' >
                <div style={{ width: '100%', display: 'flex' }}>
                  <Button
                    style={{ padding: '3px 9px', border: 'unset', borderRadius: 8, textTransform: 'capitalize', margin: 'auto' }}
                    component={Link}
                    disabled={true}
                    to={`/my_studies/overview/${studyId}/survey-questions`}
                    variant='outlined'
                    endIcon={<ChevronRightIcon />}
                  >
                    Edit Survey details
                  </Button>
                </div>
              </Tooltip>
              <Divider style={{ margin: '16px 0px' }} />
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <div>
                  <Typography variant='h6'>Panels</Typography>
                  <Typography style={{ paddingBottom: 16 }} variant='body2'>
                    {studiesOverview.audience_overview.participants_requested} participants requested
                  </Typography>
                </div>
                <Tooltip title='Create panel'>
                  <IconButton onClick={() => handleOpenModal('CREATE_AUDIENCE')} color='primary' >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </div>
              {studiesOverview.audience_overview.panels.map((item, index) => (
                <div key={index}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                      <Typography variant='subtitle2'>{index + 1}) {item.name}</Typography>
                    </div>
                    <EditPanelMenu
                      key={index}
                      onEditClick={() => handleOpenModal('EDIT_AUDIENCE', item.id)}
                      onDeleteClick={() => handleOpenConfirmationModal('DELETE_CONFIRMATION', item)}
                    />
                  </div>
                </div>
              ))}
            </Paper>
            <div >
              <Paper
                variant='outlined'
                style={{
                  borderRadius: 16,
                  padding: 16,
                  marginBottom:24,
                  display: 'flex',
                  background: 'rgb(200, 250, 205)',
                  justifyContent: 'space-between'
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', gap: 8 }}>
                  <div>
                    <Typography style={{ padding: ' 8px 16px 0px', whiteSpace: 'nowrap' }} variant='h6'>
                      Congratulations {homeReducer.first_name ? homeReducer.first_name : ''}
                    </Typography>
                    <Typography style={{ padding: '0px 16px 16px', color: 'rgb(99, 115, 129)' }} variant='subtitle1'>
                      Your results are now ready for inspection
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center'}}>
                    <Button
                      style={{
                        background: 'rgb(0, 171, 85)',
                        whiteSpace: 'nowrap',
                        color: 'white',
                        border: 'unset',
                        borderRadius: 4,
                        textTransform: 'capitalize',
                        margin: 'auto',
                      }}
                      component={Link}
                      to={`/my_studies/overview/${studyId}/reports-new`}
                      variant='contained'
                    >
                      View results
                    </Button>
                  </div>
                </div>
              </Paper>
              <Paper variant='outlined' style={{ borderRadius: 16, padding: 16 }} >
                <Typography style={{ padding: '0px 8px 16px' }} variant='h6'>Panel links</Typography>
                <NewLinksTable data={links} />
              </Paper>



            </div>
            <div>
              <Paper variant='outlined' style={{ borderRadius: 16, padding: 16, marginBottom:24, minWidth: 330 }} >
                <StudyTimeline phaseId={studiesOverview.phase_id} postEndLiveStudy={postEndLiveStudy} studyId={studyId} />
              </Paper>
              <Paper variant='outlined' style={{ borderRadius: 16, padding: 16, marginBottom: 24 }} >
                <Typography style={{ display: 'flex', justifyContent: 'space-between', padding: '0px 8px 0px' }} variant='h6'>
                  Actions
                  <ConfirmActionModal
                    openButtonText={'Subscribe Me'}
                    action={submitSubscribeToStudy}
                    className='SubscribeToStudy'
                    title='Subscribe to this study?'
                    mainText={<>Add <span style={{ fontStyle: 'italic' }}>{user.email}</span> to receive updates on <span style={{ fontStyle: 'italic' }}>{studiesOverview.study_name}</span> study</>}
                  />
                </Typography>
                {studyActions?.length ? (
                  studyActions.map(({name, type, assignments}) => (
                    <div key={type}>
                      <Typography style={{ paddingTop: 16, color: 'rgb(99, 115, 129)', display: 'flex', alignItems: 'center', gap: 4, padding: '0px 8px 0px' }}>{name}:</Typography>
                      {assignments.map(({email}) => (
                        <Typography
                          style={{ color: 'rgb(99, 115, 129)', display: 'flex', alignItems: 'center', gap: 4, padding: '0px 8px 0px' }}
                          variant='body2'
                          key={email}
                        >
                          <li>{email}</li>
                        </Typography>
                      ))}
                    </div>
                  ))
                ) : null}
              </Paper>
              <Paper variant='outlined' style={{ borderRadius: 16, padding: 16 }} >
                <Typography style={{ padding: '0px 8px 0px' }} variant='h6' >Your stims</Typography>
                <Typography style={{ padding: '0px 8px 16px', color: 'rgb(99, 115, 129)' }} variant='caption' >
                  You have {studiesOverview.cell_step_stim_overview.stim_count} stims in this study
                </Typography>
                <StudySpecificStimsContainer />
              </Paper>
            </div>
          </div>
          <div className='overview__mini-card-container'>
            <Status studyData={studiesOverview} />
          </div>
        </div>
      </Paper>
      <ModalRoot />
    </div>
  );
};

Overview.propTypes = {
  match: propTypes.object.isRequired,

};

const mapStateToProps = (state) => {
  return {
    studiesOverview: state.isolationData.studiesOverview,
    loading: state.isolationData.studiesOverviewLoading,
    modalOpen: state.overview.modalOpen,
    panelOverview: state.overview.panelOverview,
    confirmationModalOpen: state.overview.confirmationModalOpen,
    panelLoading: state.isolationData.panelLoading,
    overviewLoading: state.overview.overviewLoading,
    filesModalOpen: state.overview.filesModalOpen,
    homeReducer: state.homeReducer,
    links: state.links.links,
    linksLoading: state.links.linksLoading,
    user: state.userData.userData,
    studyDetails: state.editStudyDetails.studyDetails,
    studyActions: state.overview.studyActions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchStudy: (study) => (dispatch(studiesOperations.fetchStudy(study))),
    getAudienceOverviewData: (id) => (dispatch(overviewOperations.getAudienceOverviewData(id))),
    getOverviewData: (id) => (dispatch(overviewOperations.getOverviewData(id))),
    openModal: (id, data) => (dispatch(overviewOperations.openModal(id, data))),
    openConfirmationModal: (id, data) => (dispatch(overviewOperations.openConfirmationModal(id, data))),
    closeConfirmationModal: (id, data) => (dispatch(overviewOperations.closeConfirmationModal(id, data))),
    fetchStudySpecificStims: (id) => (dispatch(studiesOperations.fetchStudySpecificStims(id))),
    fetchStudyDetails: (id) => dispatch(overviewOperations.fetchStudyDetails(id)),
    getLinksData: (data) => dispatch(LinksOperations.getLinksData(data)),
    postEndLiveStudy: (id) => (dispatch(studiesOperations.postEndLiveStudy(id))),
    getUserData: () => dispatch(userOperations.getUserData()),
  };
};

Overview.propTypes = {
  getAudienceOverviewData: propTypes.func,
  getOverviewData: propTypes.func,
  fetchStudy: propTypes.func,
  fetchStudySpecificStims: propTypes.func,
  fetchStudyDetails: propTypes.func,
  openModal: propTypes.func,
  openConfirmationModal: propTypes.func,
  confirmationModalOpen: propTypes.bool,
  closeConfirmationModal: propTypes.func,
  modalOpen: propTypes.bool,
  match: propTypes.object,
  panelOverview: propTypes.object,
  loading: propTypes.bool,
  studiesOverview: propTypes.object,
  isolationData: propTypes.object,
  panelLoading: propTypes.bool,
  overviewLoading: propTypes.bool,
  homeReducer: propTypes.object,
  getLinksData: propTypes.func,
  links: propTypes.object,
  postEndLiveStudy: propTypes.func,
  postAssignToAction: propTypes.func,
  user: propTypes.object,
  getUserData: propTypes.func,
  studyActions: propTypes.array,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Overview));
