import React, {useState, useEffect, useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import StimService from '../StimService';
import CreateBrand from './CreateBrand';
import UploadStimDisplay from './UploadStimDisplay';
import StimRow from './StimRow';

const UploadStimPage = ()=>{
  const [selectedType, setSelectedType] = useState('s');
  const [scriptData ,setScriptData] = useState('');
  const [vastUrl ,setVastUrl] = useState('');
  const [file, setFile] = useState([]);
  const [preview ,setPreview] = useState([]);

  const [uploadList, setUploadList] = useState([]);
  const [rowErrors,setRowErrors] = useState([]);
  const [error, setError] = useState(null);
  const [uploading,setUploading] = useState(false);

  const [adAssetTypes,setAdAssetTypes] = useState([]);
  const [adTypeGroups, setAdTypeGroups] = useState([]);
  const [adTypes, setAdTypes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brandCategories, setBrandCategories] = useState([]);

  const [loading, setLoading]=useState({
    adAssetTypes: true ,
    brands:true,
    categories: true,
  });

  const [newBrandModalOpen,setNewBrandModalOpen]= useState(false);
  const [newBrandName,setNewBrandName]= useState('');
  const [newBrandCategory,setNewBrandCategory]= useState('');
  const [isSuperUser, setIsSuperUser]= useState(false);

  useEffect(() =>{
    (async()=>{
      try{
        const [aaRes, bRes,cRes] =await Promise.all([
          StimService.getAdAssetTypes() ,
          StimService.getBrands(),
          StimService.getBrandCategories(),
        ]);

        setAdAssetTypes(aaRes.data);
        setBrands(bRes.data);

        setBrandCategories(cRes.data);
      } 

      catch {
        setError('Failed to load initial data.');
      } finally {
        setLoading({ adAssetTypes: false, brands: false, categories: false });
      }
    })();
    setIsSuperUser(localStorage.getItem('superUser')==='true');
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    setFile(prev => [...prev, ...acceptedFiles]);
    setPreview(prev => [...prev, ...acceptedFiles.map(file=>URL.createObjectURL(file))]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } =useDropzone({
    accept: {
      'image/*':[],
      'video/*':[],
    } ,
    multiple:true,
    onDrop,
  });

  const handleTypeChange = (e)=>{
    setSelectedType(e.target.value);
    setScriptData('');
    setVastUrl('');
    setFile([]);
    setPreview([]);
  };

  const handleAddToUploadList =()=> {
    setError(null);
    let valid = true;
  
    if (selectedType ==='s' && !scriptData.trim()){
      setError('Script cannot be empty.');
      valid = false;
    }
  
    if (selectedType === 'va' && !vastUrl.trim()){
      setError('VAST URL/XML cannot be empty.');
      valid = false;
    }
  
    if (selectedType === 'iv' && file.length === 0){
      setError('Please upload at least one image or video.');
      valid = false;
    }
  
    if (!valid) return;
  
    const newItems =[];
  
    if (selectedType ==='s') {
      newItems.push({
        name: '',
        scriptData,
        adAssetTypeId: '',
        adTypeGroupId: '',
        adTypeId: '',
        brandId: '',
      });
    } else if (selectedType==='va') {
      newItems.push({
        name: '',
        vastUrl,
        adAssetTypeId: '',
        adTypeGroupId: '',
        adTypeId: '',
        brandId: '',
      });
    } else if (selectedType ==='iv'){
      file.forEach((f, i) => {
        newItems.push({
          name: '',
          file: f,
          preview: preview[i],
          adAssetTypeId: '',
          adTypeGroupId: '',
          adTypeId: '',
          brandId: '',
        });
      });
    }
  
    setUploadList(prev => [...prev,...newItems]);
    setScriptData('');
    setVastUrl('');
    setFile([]);
    setPreview([]);
  };
  

  const handleFieldChange = async (index, updates)=>{
    const updated =[...uploadList];
    const currentStim= updated[index];

    updated[index]={...currentStim,...updates};

    if (updates.adAssetTypeId) {
      updated[index].adTypeGroupId = '';
      updated[index].adTypeId = '';
      const groupRes = await StimService.getAdTypeGroups(updates.adAssetTypeId);
      updated[index].adTypeGroups = groupRes.data; // Save locally
      updated[index].adTypes = []; 
    }
  

    
    if (updates.adTypeGroupId){
      updated[index].adTypeId = '';
      const typeRes = await StimService.getAdTypes(updates.adTypeGroupId);
      updated[index].adTypes = typeRes.data; 
    }

    setUploadList(updated);
  };

  const handleDelete = (index) => {
    const updated = [...uploadList];
    updated.splice(index, 1);
    setUploadList(updated);
    const updatedErrors = [...rowErrors];
    updatedErrors.splice(index, 1);
    setRowErrors(updatedErrors);
  };

  const handleCreateBrand = async ()=>{
    if (!newBrandName || !newBrandCategory) {
      setError('Brand name and category are required');
      return;
    }
    try {
      await StimService.createBrand(newBrandName, newBrandName.toLowerCase(), newBrandCategory);
      const newBrand = {
        id: newBrandName.toLowerCase(),
        name: newBrandName,
        category: newBrandCategory,
      };
      setBrands(prev => [...prev, newBrand]);
      setNewBrandModalOpen(false);
      setNewBrandName('');
      setNewBrandCategory('');
      alert('Brand created successfully');
    } catch {
      setError('Failed to create brand.');
    }
  };

  const isXmlString = (str) => {
    try {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(str, 'application/xml');
      return !xmlDoc.querySelector('parsererror');
    } catch {
      return false;
    }
  };

  const handleUpload = async () => {
    if (!uploadList.length) return setError('No stims to upload.');

    const newErrors = uploadList.map((stim) => {
      const errs = {};
      if (!stim.name) errs.name = 'Name is required';
      if (!stim.adAssetTypeId) errs.adAssetTypeId = 'Required';
      if (!stim.adTypeGroupId) errs.adTypeGroupId = 'Required';
      if (!stim.adTypeId) errs.adTypeId = 'Required';
      if (!stim.brandId) errs.brandId = 'Required';
      if (!stim.scriptData && !stim.vastUrl && !stim.file)
        errs.tag = 'Stim content is required';
      return errs;
    });

    const hasErrors = newErrors.some((e)=>Object.keys(e).length>0);
    if (hasErrors) {
      setRowErrors(newErrors);
      setError('Please fix the errors before uploading.');
      return;
    }

    setError(null);
    setRowErrors([]);

    setUploading(true);

    for (const stim of uploadList)
  {

      let stimType = '';
      let sourceUrl = '';
      let tagData = '';

      if (stim.scriptData) 
      {
        stimType = 's';
        tagData = stim.scriptData;
      } 
      else if (stim.vastUrl) 
      {
        stimType = isXmlString(stim.vastUrl) ? 'vx' : 'va';
        tagData = stim.vastUrl;
      } 
      else if (stim.file) 
        {
        stimType = stim.file.type.includes('video') ? 'v' : 'i';
        try {
          const res = await StimService.getPresignedUrl(stim.file.name);
          await fetch(res.data.url, {
            method: 'PUT',
            headers: { 'Content-Type': stim.file.type },
            body: stim.file,
          });

          sourceUrl = res.data.url.split('?')[0];
        } catch {
          setError(`Failed to upload file for ${stim.name}`);
          continue;
        }
      }

      const payload = 
      {
        name: stim.name, 
        ad_type_id: stim.adTypeId ,
        ad_asset_type_id: stim.adAssetTypeId ,
        ad_type_group_id: stim.adTypeGroupId ,
        brand_id: stim.brandId ,
        tag: tagData,
        stim_type: stimType,
        source: sourceUrl,
        file_name: stim.file ? stim.file.name :'',
      };

      try {
        await StimService.uploadStim(payload);
      } catch (e) {
        setError(`Failed to upload ${stim.name}`);
      }

    }

    alert('All stims uploaded!'); 
    setUploadList([]); 
    setRowErrors([]);
    setUploading(false);
  };

  return (

    <div style={{ padding: '30px' }}>

      <UploadStimDisplay
        selectedType={selectedType}
        scriptData={scriptData}
        vastUrl={vastUrl}
        file={file}
        preview={preview}
        isDragActive={isDragActive}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        handleTypeChange={handleTypeChange}
        setScriptData={setScriptData}
        setVastUrl={setVastUrl}
        handleAddToUploadList={handleAddToUploadList}
        handleUpload={handleUpload}
        uploading={uploading}
        errors={error ? { global: error } : {}}
        setFile={setFile}       
        setPreview={setPreview}  
      />

      <div style={{ marginTop: '30px' }}>

        {uploadList.map((stim, index) => (
          <StimRow
            key={index}
            index={index}
            stim={stim}
            adAssetTypes={adAssetTypes}
            adTypeGroups={stim.adTypeGroups|| []}
            adTypes={stim.adTypes|| []}
            brands={brands}
            brandCategories={brandCategories}
            isSuperUser={isSuperUser}
            onFieldChange={handleFieldChange}
            onDelete={handleDelete}
            onCreateBrand={() => setNewBrandModalOpen(true)}
            errors={rowErrors[index] || {}}
          />
        ))}
      </div>

      <CreateBrand

        open={newBrandModalOpen}
        onClose={() => setNewBrandModalOpen(false)}
        brandCategories={brandCategories}
        loadingCategories={loading.categories}
        newBrandName={newBrandName}
        setNewBrandName={setNewBrandName}
        newBrandCategory={newBrandCategory}
        setNewBrandCategory={setNewBrandCategory}
        handleCreateBrand={handleCreateBrand}
      />
    </div>

  );
};

export default UploadStimPage;
