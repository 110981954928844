import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Login from './Login/Login';
import ForgotPassword from './containers/ForgotPassword/ForgotPassword';
import MyDetails from './MyDetails/MyDetailsContainer';
import ErrorPage from './containers/ErrorPage/ErrorPage';
import requireAuth from './common/hoc/RequireAuth';
import noRequireAuth from './common/hoc/NoRequireAuth';
import Signout from './components/Signout';
import Header from './components/Header';
import Dashboard from './Dashboard/DashboardContainer';
import Demographics from './Demographics/DemographicsContainer';
import ScrollToTop from './common/shared/Scroll';
import ContactUs from './ContactUs/ContactUsContainer';
import NewHome from './NewHome/NewHomeContainer';
import ModelContainer from './Model/ModelContainer';
import CellStepManagementContainer from './CellStepManagement/CellStepManagementContainer';
import Overview from './Overview/OverviewContainer';
import EditAudienceContainer from './EditAudience/EditAudience';
import EditStudyDetails from './EditStudyDetails/EditStudyDetailsContainer';
import Stims from './Stims/StimsContainer';
import Aoi from './Aoi/AoiContainer';
import BatchStims from './Batches/BatchesContainer';
import BatchStimList from './BatchData/BatchDataContainer';
import BatchKpi from './BatchKpi/BatchKpiContainer';
import BatchOverview from './BatchOverview/BatchOverviewContainer';
import Files from './Files/FilesContainer';
import LookerReport from './LookerReport/LookerReportContainer';
import CacheBuster from './CacheBuster';
import SplashPage from './CreateStudyPage/SplashPage';
import DigitalStandardContainer from './CreateStudyPage/DigitalStandard/DigitalStandardContainer';
import DigitalRichContainer from './CreateStudyPage/DigitalRich/DigitalRichContainer';
import SocialMediaContainer from './CreateStudyPage/SocialMedia/SocialMediaContainer';
import CustomStudyContainer from './CreateStudyPage/CustomStudy/CustomStudyContainer';
import YouTubeContainer from './CreateStudyPage/YouTube/YouTubeContainer';
import LumenPanelContainer from './CreateStudyPage/LumenPanel/LumenPanelContainer';
import DecipherContainer from './CreateStudyPage/Decipher/DecipherContainer';
import ThankYouPage from './CreateStudyPage/ThankYouPage';
import ReportsContainer from './Reports/ReportsContainer';
import FeedbackForm from './CreateStudyPage/MaterialComponents/FeedbackForm';
import BatchKpiAndFeature from './BatchKpi/BatchKpiAndFeature';
import UploadStimPage from './Stims/components/UploadStimPage';
import EditStim from './Stims/components/EditStim';

function Main() {
  const userType = parseInt(localStorage.getItem('userType'));
  const homepage = userType === 1 ? Dashboard : BatchStims;
  return (
    <main style={{padding: '96px 32px 24px 86px', minHeight: '100vh', background: '#eceff1'}} >
      <Switch>
        <Route exact path='/login' component={noRequireAuth(Login)} />
        <Route path='/forgot_password' component={noRequireAuth(ForgotPassword)} />
        <Route path='/request_login' component={noRequireAuth(ContactUs)} />
        <Route path='/contact_us' component={requireAuth(ContactUs)} />
        <Route path='/my_account' component={requireAuth(MyDetails)} />
        <Route path='/stims/aoi/:id' component={requireAuth(Aoi)} />
        <Route path='/batch_stim_list/aoi/:id' component={requireAuth(Aoi)} />
        <Route path='/batch_stim_list' component={requireAuth(BatchStimList)} />
        <Route path='/my_batches/batch_overview/:id/kpi' component={requireAuth(BatchKpi)} />
        <Route path='/my_batches/batch_overview/:id/stim_report' component={requireAuth(BatchKpiAndFeature)} />
        <Route path='/my_batches/batch_overview/:id' component={requireAuth(BatchOverview)} />
        <Route path="/stims/upload-stim" component={UploadStimPage} />
        <Route path="/stims/edit/:id" component={EditStim} />
        <Route path='/stims' component={requireAuth(Stims)} />

        {/* <Route path="/my_studies/overview/study-specific-stims/:studyId" component={requireAuth(Stims)} /> */}
        <Route path="/my_studies/overview/:studyId/study-specific-stims/" component={requireAuth(Stims)} />


        <Route path='/my_downloads' component={requireAuth(Files)} />
        <Route path='/my_reports' component={requireAuth(LookerReport)} />
        <Route path='/my_studies/overview/:id/demographics' component={requireAuth(Demographics)} />
        <Route path='/my_studies/overview/:id/cell_step_management' component={requireAuth(CellStepManagementContainer)} />
        <Route path='/my_studies/overview/:id/reports-old' component={requireAuth(ReportsContainer)} />
        <Route path='/my_studies/overview/:id/reports-new' component={requireAuth(LookerReport)} />
        <Route path='/my_studies/overview/:id/edit-details' component={requireAuth(EditStudyDetails)} />
        <Route path='/my_studies/overview/:id/edit-audience' component={requireAuth(EditAudienceContainer)} />
        <Route path='/my_studies/overview/:id/thank_you' component={requireAuth(ThankYouPage)} />
        <Route path='/my_studies/overview/:id' component={requireAuth(Overview)} />
        <Route path='/create-study/social-media-study' component={requireAuth(SocialMediaContainer)} />
        <Route path='/create-study/digital-ad-test' component={requireAuth(DigitalStandardContainer)} />
        <Route path='/create-study/digital-rich-study' component={requireAuth(DigitalRichContainer)} />
        <Route path='/create-study/YouTube-study' component={requireAuth(YouTubeContainer)} />
        <Route path='/create-study/Lumen-panel-setup' component={requireAuth(LumenPanelContainer)} />
        <Route path='/create-study/vast-ad-test' component={requireAuth(DecipherContainer)} />
        <Route path='/create-study/custom-study' component={requireAuth(CustomStudyContainer)} />
        <Route path='/create-study' component={requireAuth(SplashPage)} />
        <Route path='/signout' component={requireAuth(Signout)} />
        <Route path='/lumen-research' component={() => window.location = 'https://www.lumen-research.com/'} />
        <Route path='/my_batches' component={requireAuth(BatchStims)} />
        <Route path='/my_studies' component={requireAuth(homepage)} />
        <Route path='/' component={requireAuth(NewHome)} />
        <Route path='*' component={noRequireAuth(ErrorPage)} />
        <Route path='*' component={requireAuth(ErrorPage)} />
        
      </Switch>
    </main>
  );
}

const App = ({ isModalOpen }) => (
  <CacheBuster>
    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
      if (loading) return null;
      if (!loading && !isLatestVersion) {
        refreshCacheAndReload();
      }
      return (
        <div style={{width:'100%'}} >
          <Header />
          <ScrollToTop>
            {isModalOpen ? <ModelContainer /> : null}
            <Main />
            <FeedbackForm/>
          </ScrollToTop>
        </div>
      );
    }}
  </CacheBuster>
);

const mapStateToProps = (state) => {
  return {
    isModalOpen: state.filter.modalOpen
  };
};

App.propTypes = {
  isModalOpen: PropTypes.bool,
};

export default withRouter(connect(mapStateToProps, null)(App));
